import { createServer, RestSerializer, Response } from "miragejs";

export function makeServer() {
    return createServer({
        serializers: {
            application: RestSerializer,
        },

        routes() {
            this.namespace = "/__api.cfm";

            this.post("/", () => {
                return new Response(
                    201,
                    {},
                    {
                        errormessage: "",
                        errornumber: 0,
                        result: [
                            "dsps",
                            "dspace",
                            "dsp56k",
                            "dspace's",
                            "slave-dsp",
                            "dspace.vs",
                            "dspace.hr",
                            "dspace.fr",
                            "dspace.de",
                            "dspace.log",
                        ],
                        success: true,
                    }
                );
            });
        },
    });
}
